// 3rd-party modules
import { message } from "antd";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import AccountInternetScheduleModal from "./AccountInternetScheduleModal";
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { AccountDevice, AccountDeviceSchedule } from "../../../models/accountDevice";

type Props = {
  account?: Account;
  filterId?: any;
  filterSetId?: any;
  deviceReference?: string;
  refresh?: boolean;
  kiboshDeviceReference?: string;
  minimal?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

type tableDataHours = {
  id: string,
  data: string,
  sortParameter: number,
  color: string;
}

type tableData = {
  day: string,
  dayKey: string,
  hours: tableDataHours[]
}

const COLORS: string[] = [
  "deep-orange",
  "purple",
  "pink",
  "light-green",
  "red",
  "pink",
  "indigo",
  "blue",
  "light-blue",
  "cyan",
  "deep-purple",
  "brown",
  "blue-gray",
  "amber",
  "teal"
]

export default function AccountInternetSchedules({ account, filterId = "", filterSetId = "", minimal = false, deviceReference, refresh, kiboshDeviceReference, onCancel, onSave }: Props) {
  const columns: TableColumn<any>[] = [
    {
      title: () => <text data-weight="600" data-space="10">Week Days</text>,
      dataIndex: 'day',
      key: 'day',
      width: 120,
      render: (text) => <text data-weight="600" data-space="10">{text}</text>
    },
    {
      title: <text data-weight="600" data-space="10">Blocked Hours</text>,
      dataIndex: '',
      key: '',
      render: (_, record: tableData) =>
        <group data-gap="5">
          {
            record.hours.sort((a: any, b: any) => b.sortParameter - a.sortParameter).map(hour =>
              <group key={hour.id} data-color={hour.color} data-background="" data-width="auto" data-align="center" data-wrap="no" data-border="outline" data-radius="5" data-contain="" onClick={() => onEditClick(accountDeviceSchedules.find(x => x.id === hour.id)!)}>
                <div className="button" data-shrink="no" data-radius="0" effect="material"><text data-weight="400">{hour.data}</text></div>
                <separator vertical=""></separator>
                <div className="button micro" data-radius="0" effect="material" onClick={(e: any) => { e.stopPropagation(); onDeleteClick(accountDeviceSchedules.find(x => x.id === hour.id)!) }}><icon data-color="black">clear</icon></div>
              </group>
            )
          }
        </group>
    },
  ];
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [accountDeviceSchedules, setAccountDeviceSchedules] = useState<AccountDeviceSchedule[]>([]);
  const [datasource, setDatasource] = useState<any[]>([]);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [currentAccountDeviceReference, setCurrentAccountDeviceReference] = useState<string>("");
  const [currentAccountDeviceSchedule, setCurrentAccountDeviceSchedule] = useState<AccountDeviceSchedule>(new AccountDeviceSchedule());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountDeviceScheduleModal, setShowAccountDeviceScheduleModal] = useState(false);
  // const [totalRecords, setTotalRecords] = useState<number>(0);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) {
      setReload(false);
      getPage();
    }
  }, [reload]);

  useEffect(() => {
    let customData: tableData[] = [
      {
        day: 'Monday',
        dayKey: 'mon',
        hours: []
      },
      {
        day: 'Tuesday',
        dayKey: 'tue',
        hours: []
      },
      {
        day: 'Wednesday',
        dayKey: 'wed',
        hours: []
      },
      {
        day: 'Thursday',
        dayKey: 'thu',
        hours: []
      },
      {
        day: 'Friday',
        dayKey: 'fri',
        hours: []
      },
      {
        day: 'Saturday',
        dayKey: 'sat',
        hours: []
      },
      {
        day: 'Sunday',
        dayKey: 'sun',
        hours: []
      }
    ]

    for (let index = 0; index < accountDeviceSchedules.length; index++) {
      const accountDeviceSchedule = accountDeviceSchedules[index];
      customData.forEach(item => {
        if (accountDeviceSchedule.activeDays?.includes(item.dayKey)) {
          item.hours.push({
            id: accountDeviceSchedule.id!,
            data: `${accountDeviceSchedule.fromTime} - ${accountDeviceSchedule.toTime}`,
            sortParameter: (accountDeviceSchedule.fromHour || 0) * 100 + (accountDeviceSchedule.fromMinute || 0),
            color: COLORS[index % COLORS.length]
          })
        }
      });
    }

    if (minimal) {
      customData = customData.filter(x => x.hours.length);
    }

    setDatasource(customData);
  }, [accountDeviceSchedules]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      } else {
        setReload(true);
      }
    }
  }, [refresh]);

  useEffect(() => {
    setReload(true);
  }, [filterId])

  const getAccountDevicesAsync = async (routerRef?: string) => {
    const allOption = new AccountDevice();
    allOption.name = "All";
    allOption.id = "All";
    setAccountDevices([allOption, ...await getAccountDevices(routerRef, abortController.signal)]);
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    if (response.success) {
      return AccountDevice.toArrayOfClass(response.data?.value || []).map((x: any) => {
        return {
          ...x,
          sorter: x.groupId ? Number(x.groupId) : Infinity
        }
      }).sort((a: any, b: any) => a.sorter - b.sorter);

    }
    else {
      return [];
    }
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentAccountDeviceReference && !deviceReference)
      return [];
    let currentFilterId = filterId;
    if (filterSetId && !currentFilterId) {
      currentFilterId = -1;
    }

    setLoading(true);
    const response = await apiCall(DashApi.getDeviceSchedules(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, currentFilterId, abortSignal));
    // setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setAccountDeviceSchedules(response.success ? AccountDeviceSchedule.toArrayOfClass(response.data?.value || []) : []);
    setLoading(false);

    return response.success ? AccountDeviceSchedule.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAccountDeviceScheduleSave = (e: Account) => {
    // setTimeout(async () => {
    //   // setAccounts(await getAccounts(undefined, abortController.signal));
    // }, 50);
    setReload(true);
    if (onSave) {
      onSave(account!);
    }
  }

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (deviceReference: any) => {
    setCurrentAccountDeviceReference(deviceReference);
    setReload(true);
  }

  const onEditClick = (accountDeviceSchedule: AccountDeviceSchedule) => {
    setCurrentAccountDeviceSchedule({ ...accountDeviceSchedule });
    setShowAccountDeviceScheduleModal(true);
  };

  const onNewClick = () => {
    setCurrentAccountDeviceSchedule(new AccountDeviceSchedule());
    setShowAccountDeviceScheduleModal(true);
  };

  const onDeleteClick = (accountDeviceSchedule: AccountDeviceSchedule) => {
    setCurrentAccountDeviceSchedule(accountDeviceSchedule);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteDeviceSchedule(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, currentAccountDeviceSchedule.id!, filterId));
    setLoading(false);

    if (response.success) {
      message.success(`Account Device Schedule deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  return (
    <>
      {loading &&
        <Loader />
      }
      <view data-scroll="">
        {(!kiboshDeviceReference || !deviceReference) &&
          <group data-space="15" data-gap="20" data-border="">
            {!kiboshDeviceReference && <>
              <Select
                autoComplete=""
                label="Kibosh Device"
                labelPosition="left"
                dataLength="320"
                onChange={onAccountKiboshDeviceChange}
                loading={loadingAccountKiboshDevices}
                allowSearch={true}
                options={
                  accountKiboshDevices?.map((item) => {
                    return {
                      text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                      value: item.kiboshDeviceReference!,
                    };
                  }) || []
                }
              />
              <Button
                material
                icon="refresh"
                text="Refresh"
                outline
                onClick={getAccountKiboshDevicesAsync}
              />
            </>}
            {!deviceReference && <>
              <Select
                autoComplete=""
                label="Device"
                labelPosition="left"
                dataLength="320"
                onChange={onAccountDeviceChange}
                loading={loadingAccountDevices}
                allowSearch={true}
                options={
                  accountDevices?.map((item) => {
                    return {
                      text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                      value: item.id!,
                    };
                  }) || []
                }
                button={
                  <Button
                    material
                    icon="refresh"
                    onClick={getAccountDevicesAsync}
                  />
                }
              />
            </>}
          </group>}
          <view className="table_cont" data-background="none"
          //data-radius="10"
          >
            <group data-space="15" data-gap="20" data-border="" data-background="highlight">
              <Button
                material
                icon="add"
                text="New"
                primary
                onClick={onNewClick}
              />
              <Button
                data-position="right"
                material
                icon="refresh"
                text="Refresh"
                outline
                onClick={() => setReload(true)}
              />
            </group>
            <DataTable
              columns={columns}
              bordered={true}
              reload={reload}
              dataSource={datasource}
              loading={loading}
              rowKey={record => `${record.dayKey}`}
              size="small"
              pagination={false}
              // showTotal={false}
              // totalRecords={totalRecords}
            // onRow={(record) => ({
            //   onDoubleClick: () => onEditClick(record),
            //   style: { cursor: "pointer" },
            // })}
            />
          </view>

      </view>
      {!!showAccountDeviceScheduleModal &&
        <AccountInternetScheduleModal open={showAccountDeviceScheduleModal}
          account={account!}
          deviceReference={deviceReference || currentAccountDeviceReference}
          kiboshDeviceReference={kiboshDeviceReference || currentAccountKiboshDeviceReference}
          accountDeviceSchedule={currentAccountDeviceSchedule}
          filterId={filterId}
          filterSetId={filterSetId}
          limitedDays={filterSetId ? datasource.filter(day => day.hours.length >= 2).map(day => day.dayKey ) : []}
          closeOnSave={true}
          onClose={() => setShowAccountDeviceScheduleModal(false)}
          onSave={(e) => onAccountDeviceScheduleSave(e)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
