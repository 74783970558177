// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import Loader from "../../components/shared/loader";
import { apiCall } from "../../helpers/apiHelper";
import { Account } from "../../models/account";

// apis
import * as AuthApi from "../../apis/authApi"
import AccountKiboshDeviceMenuModal from "../../components/accounts/kibosh-device/AccountKiboshDeviceMenuModal";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { AccountKiboshDevice } from "../../models/accountKiboshDevice";
import { useNavigate } from "react-router-dom";

export default function DashPage() {
  const kiboshDevice = useSelector((state: RootState) => state.user.kiboshDevice) || new AccountKiboshDevice();

  const [currentAccount, setCurrentAccount] = useState<Account>(new Account());
  const [loading, setLoading] = useState<boolean>(false);
  const [showAccountKiboshDeviceMenuModal, setShowAccountKiboshDeviceMenuModal] = useState(true);

  const navigate = useNavigate();
  let abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    getAccountAsync();

    return () => { abortController.abort(); }
  }, []);

  useEffect(()=> {
    if (!showAccountKiboshDeviceMenuModal) {
      navigate(-1);
    }
  },[showAccountKiboshDeviceMenuModal]);

  const getAccountAsync = async () => {
    const account = await getAccount(abortController.signal);

    if (account) {
      setCurrentAccount(account!);
    }
  }

  const getAccount = async (abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(AuthApi.getAccount(abortSignal));
    setLoading(false);

    return response.success ? Account.toClass(response.data?.value) : null;
  };

  return (
    <>
      {loading && <Loader/>}
        <AccountKiboshDeviceMenuModal
          open={showAccountKiboshDeviceMenuModal}
          closeOnSave={true}
          account={currentAccount!}
          accountKiboshDevice={kiboshDevice}
          onClose={() => setShowAccountKiboshDeviceMenuModal(false)}
        />
    </>
  );
}
